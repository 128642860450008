'use strict';

import {ObjectUtils} from './ObjectUtils';
import {
    DistressCause,
    SurfaceType,
    Measurement
} from '@totalpave/interfaces';

export const ACC_ALLIGATOR_CRACKING                 = 1;
export const ACC_BLOCK_CRACKING                     = 2;
export const ACC_BUMPS_SAGS                         = 3;
export const ACC_CORRUGATION                        = 4;
export const ACC_DEPRESSION                         = 5;
export const ACC_EDGE_CRACKING                      = 6;
export const ACC_JOINT_REFLECTION_CRACKING          = 7;
export const ACC_LANE_SHOULDER_DROP_OFF             = 8;
export const ACC_LONGITUDINAL_TRANSVERSE_CRACKING   = 9;
export const ACC_PATCHING_UTILITY_CUT_PATCHING      = 10;
export const ACC_POTHOLES                           = 11;
export const ACC_RUTTING                            = 12;
export const ACC_SLIPPAGE_CRACKING                  = 13;
export const ACC_SHOVING                            = 14;
export const ACC_BLEEDING                           = 15;
export const ACC_POLISHED_AGGREGATE                 = 16;
export const ACC_RAILROAD_CROSSING                  = 17;
export const ACC_SWELL                              = 18;
export const ACC_WEATHERING                         = 19;
export const ACC_RAVELING                           = 20;
export const PCC_BLOW_UPS                           = 21;
export const PCC_CORNER_BREAK                       = 22;
export const PCC_DIVIDED_SLAB                       = 23;
export const PCC_DURABILITY_CRACKING                = 24;
export const PCC_FAULTING                           = 25;
export const PCC_JOINT_SEAL                         = 26;
export const PCC_LANE_SHOULDER_DROP                 = 27;
export const PCC_PATCHING_LARGE                     = 28;
export const PCC_PATCHING_SMALL                     = 29;
export const PCC_POLISHED_AGGREGATE                 = 30;
export const PCC_POPOUTS                            = 31;
export const PCC_PUMPING                            = 32;
export const PCC_PUNCHOUTS                          = 33;
export const PCC_RAILROAD_CROSSING                  = 34;
export const PCC_SCALING                            = 35;
export const PCC_SHRINKAGE                          = 36;
export const PCC_SPALLING_CORNER                    = 37;
export const PCC_SPALLING_JOINT                     = 38;
export const PCC_LINEAR_CRACKING                    = 39;

//This is not exported to avoid accidental manipulation of the definition object.
const Distress = {
    '1' : {
        name : 'Alligator Cracking',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.SQUARE,
        cause : DistressCause.LOAD
    },
    '2' : {
        name : 'Block Cracking',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.SQUARE,
        cause : DistressCause.CLIMATE
    },
    '3' : {
        name : 'Bumps & Sags',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.LINEAR,
        cause : DistressCause.OTHER
    },
    '4' : {
        name : 'Corrugation',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.SQUARE,
        cause : DistressCause.OTHER
    },
    '5' : {
        name : 'Depression',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.SQUARE,
        cause : DistressCause.OTHER
    },
    '6' : {
        name : 'Edge Cracking',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.LINEAR,
        cause : DistressCause.LOAD
    },
    '7' : {
        name : 'Joint Reflection Cracking',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.LINEAR,
        cause : DistressCause.CLIMATE
    },
    '8' : {
        name : 'Lane & Shoulder Drop Off',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.LINEAR,
        cause : DistressCause.OTHER
    },
    '9' : {
        name : 'Longitudinal/Transverse Cracking',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.LINEAR,
        cause : DistressCause.CLIMATE
    },
    '10' : {
        name : 'Patching & Utility Cut Patching',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.SQUARE,
        cause : DistressCause.OTHER
    },
    '11' : {
        name : 'Potholes',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.COUNT,
        cause : DistressCause.LOAD
    },
    '12' : {
        name : 'Rutting',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.SQUARE,
        cause : DistressCause.LOAD
    },
    '13' : {
        name : 'Slippage Cracking',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.SQUARE,
        cause : DistressCause.OTHER
    },
    '14' : {
        name : 'Shoving',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.SQUARE,
        cause : DistressCause.LOAD
    },
    '15' : {
        name : 'Bleeding',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.SQUARE,
        cause : DistressCause.OTHER
    },
    '16' : {
        name : 'Polished Aggregate',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.SQUARE,
        cause : DistressCause.OTHER
    },
    '17' : {
        name : 'Railroad Crossing',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.SQUARE,
        cause : DistressCause.OTHER
    },
    '18' : {
        name : 'Swell',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.SQUARE,
        cause : DistressCause.OTHER
    },
    '19' : {
        name : 'Weathering',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.SQUARE,
        cause : DistressCause.CLIMATE
    },
    '20' : {
        name : 'Raveling',
        surface_type: SurfaceType.ACC,
        measurement: Measurement.SQUARE,
        cause : DistressCause.CLIMATE
    },
    '21' : {
        name : 'Blow Ups',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.CLIMATE
    },
    '22' : {
        name : 'Corner Break',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.LOAD
    },
    '23' : {
        name : 'Divided Slab',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.LOAD
    },
    '24' : {
        name : 'Durability Cracking',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.CLIMATE
    },
    '25' : {
        name : 'Faulting',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.OTHER
    },
    '26' : {
        name : 'Joint-Seal',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.CLIMATE
    },
    '27' : {
        name : 'Lane Shoulder Drop',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.OTHER
    },
    '28' : {
        name : 'Patching Large',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.OTHER
    },
    '29' : {
        name : 'Patching Small',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.OTHER
    },
    '30' : {
        name : 'Polished Aggregate',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.OTHER
    },
    '31' : {
        name : 'Popouts',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.OTHER
    },
    '32' : {
        name : 'Pumping',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.OTHER
    },
    '33' : {
        name : 'Punchouts',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.LOAD
    },
    '34' : {
        name : 'Railroad Crossing',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.OTHER
    },
    '35' : {
        name : 'Scaling',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.OTHER
    },
    '36' : {
        name : 'Shrinkage',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.CLIMATE
    },
    '37' : {
        name : 'Spalling Corner',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.CLIMATE
    },
    '38' : {
        name : 'Spalling Joint',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.CLIMATE
    },
    '39' : {
        name : 'Linear Cracking',
        surface_type: SurfaceType.PCC,
        measurement: Measurement.SLAB,
        cause : DistressCause.LOAD
    }
}

class DistressUtils {
    constructor() {
        throw new Error('DistressUtils is static and cannot be instantiated.');
    }

    static getACCDistresses() {
        let distresses = [];

        for (let i in Distress) {
            let distress = Distress[i];
            if (distress.surface_type === SurfaceType.ACC) {
                distresses.push({
                    type : parseInt(i),
                    name : distress.name,
                    surface_type : distress.surface_type,
                    measurement : distress.measurement,
                    cause : distress.cause
                });
            }
        }

        return distresses;
    }

    static getPCCDistresses() {
        let distresses = [];

        for (let i in Distress) {
            let distress = Distress[i];
            if (distress.surface_type === SurfaceType.PCC) {
                distresses.push({
                    type : parseInt(i),
                    name : distress.name,
                    surface_type : distress.surface_type,
                    measurement : distress.measurement,
                    cause : distress.cause
                });
            }
        }

        return distresses;
    }

    static getDistresses() {
        let distresses = [];

        for (let i in Distress) {
            let distress = Distress[i];
            distresses.push({
                type : parseInt(i),
                name : distress.name,
                surface_type : distress.surface_type,
                measurement : distress.measurement,
                cause : distress.cause
            });
        }

        return distresses;
    }

    static getSurfaceTypeByDistressType(type) {
        return Distress[type].surface_type;
    }

    static getNameByID(id) {
        return Distress[id].name;
    }

    static getDistressDefByID(id) {
        return ObjectUtils.clone(Distress[id]);
    }

    static getAllowedSeverities(id) {
        switch (id) {
            default:                        return 'LMH';
            case ACC_POLISHED_AGGREGATE:    return 'L';
            case ACC_RAVELING:              return 'MH';
            case PCC_POLISHED_AGGREGATE:    return 'L';
            case PCC_POPOUTS:               return 'L';
            case PCC_PUMPING:               return 'L';
            case PCC_SHRINKAGE:             return 'L';
        }
    }

    static getDistressCauseText(distressCause) {
        switch (distressCause) {
            case DistressCause.CLIMATE:
                return 'Climate';
            case DistressCause.LOAD:
                return 'Load';
            case DistressCause.OTHER:
                return 'Other';
            default:
                return '-';
        }
    }
}

export { DistressUtils };
export default DistressUtils;
