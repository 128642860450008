'use strict';

import {Action} from './Action';
import {OrganizationClient} from '../net/OrganizationClient';

class GetCountryListSingleton extends Action {
    getTag() {
        return 'get-country-list';
    }

    _execute() {
        return new Promise((resolve, reject) => {
            OrganizationClient.getInstance().getCountryList().then(resolve).catch(reject);
        });
    }
}

export const GetCountryList = new GetCountryListSingleton();
