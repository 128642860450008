'use strict';

import {Action} from './Action';
import {OrganizationClient} from '../net/OrganizationClient';
import {TPError} from '@totalpave/error';
import {ObjectUtils} from '@totalpave/object';

class SaveOrganizationPreferencesSingleton extends Action {
    getTag() {
        return 'save_organization_preferences';
    }

    _execute({preferences, serializer} = {}) {
        return new Promise((resolve, reject) => {

            for (let i = 0; i < preferences.length; i++) {
                let preference = preferences[i];

                let value = preference.value;
                if (typeof value !== 'string') {
                    try {
                        value = (serializer) ? serializer(value) : JSON.stringify(value);
                    }
                    catch (ex) {
                        let error = new TPError(ex);
                        error.dispatch();
                        return;
                    }
                }
                preference.value = value;

                if (ObjectUtils.isVoid(preference.version)) {
                    preference.version = 1;
                }
            }

            OrganizationClient.getInstance().savePreferences(preferences).then(() => {
                resolve(preferences);
            }).catch((ex) => {
                let error = new TPError(ex);
                error.dispatch();
                reject(error);
            });
        });
    }
}

const SaveOrganizationPreferences = new SaveOrganizationPreferencesSingleton();

export default SaveOrganizationPreferences;
export { SaveOrganizationPreferences };
