
import {Action} from './Action';

import {AuthClient} from '../net/AuthClient';

class ConfirmAccountSingleton extends Action {
    constructor() {
        super();
    }

    getTag() {
        return 'confirm-account';
    }

    _execute({code, password, confirm} = {}) {
        return new Promise((resolve, reject) => {
            AuthClient.getInstance().confirmAccount(code, password, confirm).then((user) => {
                resolve({
                    success: true,
                    user
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }
}

export const ConfirmAccount = new ConfirmAccountSingleton();
