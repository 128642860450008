
import {Action} from './Action';

import {AuthClient} from '../net/AuthClient';

class ResetPasswordSingleton extends Action {
    constructor() {
        super();
    }

    getTag() {
        return 'reset-password';
    }

    _execute({code, password, confirm} = {}) {
        return new Promise((resolve, reject) => {
            AuthClient.getInstance().resetPassword(code, password, confirm).then((user) => {
                resolve({
                    success: true,
                    user
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }
}

export const ResetPassword = new ResetPasswordSingleton();
