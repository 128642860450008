'use strict';

import {Action} from './Action';

class ClearMenusActionSingleton extends Action {
    constructor() {
        super();
        this.setForceExecute(true); //Allow menus to be removed while logging out.
    }

    _shouldDispatchOnError() {
        return false;
    }

    getTag() {
        return 'clear_menus';
    }

    _execute({menu} = {}) {
        return Promise.resolve(menu);
    }
}

const ClearMenusAction = new ClearMenusActionSingleton();

export { ClearMenusAction };
