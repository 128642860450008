'use strict';

import {Action} from './Action';
import {AuthClient} from '../net/AuthClient';
import {Localization} from '@totalpave/localization';

/*
Interfaces for the Login action
 */
class LoginActionSingleton extends Action {
    constructor() {
        super();
    }

    getTag() {
        return 'login_action';
    }

    /**
     * public execute
     * @param  {String} email 
     * @param  {String} password 
     * @param  {Boolean} persist
     * @return {void} 
     */
    _execute({email, password, persist} = {}) {
        return new Promise((resolve, reject) => {
            new Promise((resolve, reject) => {
                let localization = Localization.getInstance();
                let message;
                if (!email) {
                    if (localization.hasKey('tp-app-common/LoginForm/missing-email')) {
                        message = localization.resolve('tp-app-common/LoginForm/missing-email');
                    }
                    else {
                        message = 'Please enter your email';
                    }
                    reject(message);
                    return;
                }

                if (!password) {
                    if (localization.hasKey('tp-app-common/LoginForm/missing-password')) {
                        message = localization.resolve('tp-app-common/LoginForm/missing-password');
                    }
                    else {
                        message = 'Please enter your password';
                    }
                    reject(message);
                    return;
                }

                resolve();
            }).then(() => {
                return AuthClient.getInstance().login(email, password);
            }).then((user) => {
                resolve({
                    success : true,
                    user : user,
                    persist: persist
                });
            }).catch((error) => {
                //Web Portal uses Message system; but, the apps uses alert boxes.
                //Who ever is using LoginAction will need to determine how to handle errors.
                //error.dispatch();
                reject(error);
                // reject(error.getMessage());
            });
        });
    }
}

const LoginAction = new LoginActionSingleton();

export { LoginAction };
