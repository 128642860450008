'use strict';

// TODO: Move to finterfaces
const KeyCode = {
    ENTER           : 13,
    ESCAPE          : 27,

    ARROW_LEFT      : 37,
    ARROW_UP        : 38,
    ARROW_RIGHT     : 39,
    ARROW_DOWN      : 40,

    ZERO            : 48,
    ONE             : 49,
    TWO             : 50,
    THREE           : 51,
    FOUR            : 52,
    FIVE            : 53,
    SIX             : 54,
    SEVEN           : 55,
    EIGHT           : 56,
    NINE            : 57,

    A               : 65,
    B               : 66,
    C               : 67,
    D               : 68,
    E               : 69,
    F               : 70,
    G               : 71,
    H               : 72,
    I               : 73,
    J               : 74,
    K               : 75,
    L               : 76,
    M               : 77,
    N               : 78,
    O               : 79,
    P               : 80,
    Q               : 81,
    R               : 82,
    S               : 83,
    T               : 84,
    U               : 85,
    V               : 86,
    W               : 87,
    X               : 88,
    Y               : 89,
    Z               : 90,

    NUMPAD_ZERO     : 96,
    NUMPAD_ONE      : 97,
    NUMPAD_TWO      : 98,
    NUMPAD_THREE    : 99,
    NUMPAD_FOUR     : 100,
    NUMPAD_FIVE     : 101,
    NUMPAD_SIX      : 102,
    NUMPAD_SEVEN    : 103,
    NUMPAD_EIGHT    : 104,
    NUMPAD_NINE     : 105,
    NUMPAD_MULTIPLY : 106,
    NUMPAD_ADD      : 107,

    NUMPAD_MINUS    : 109,
    NUMPAD_DECIMAL  : 110,
    NUMPAD_DIVIDE   : 111,

    SEMICOLON       : 186,
    EQUAL           : 187,
    COMMA           : 188,
    MINUS           : 189,
    PERIOD          : 190,
    SLASH           : 191,
    BACKQUOTE       : 192,

    BRACKET_OPEN    : 219,
    BACKSLASH       : 220,
    BRACKET_CLOSE   : 221,
    QUOTE           : 222
};

export { KeyCode };
