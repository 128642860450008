'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import {IconFactory} from '../factories/IconFactory';
import {AddMenuAction} from '../actions/AddMenuAction';
import {ClearMenusAction} from '../actions/ClearMenusAction';
import {DeleteMenuAction} from '../actions/DeleteMenuAction';
import {MenuStore} from '../store/MenuStore';
import {IDGenerator} from '@totalpave/idgenerator';
import {Menu} from './Menu';
import {OptionGroup} from '../utils/OptionGroup';
import {Option} from '../utils/Option';
import {ClassName} from '../utils/ClassName';
import {Browser} from '../utils/Browser';

export class MenuItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menu : null
        };

        this._node;
        this._onClick = this._onClick.bind(this);
        this._onMouseEnter = this._onMouseEnter.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
        this._onItemClick = this._onItemClick.bind(this);
        this._id = null;
        this._userClasses = props.userClasses || {};
    }

    setUserClasses(userClasses) {
        this._userClasses = userClasses;
    }

    getUserClasses() {
        return this._userClasses;
    }

    _onClick(event) {
        if (this.props.option.isEnabled()) {
            event.stopPropagation();
            let value = this.props.option.getValue();
            if (value instanceof Array) {
                this.$showSubMenu();
            }
            else {
                if (this.props.onClick) {
                    this.props.onClick(this.props.option);
                }
            }
        }
    }

    _onItemClick(value) {
        if (this.props.option.isEnabled()) {
            window.event.stopPropagation();
            if (this.props.onClick) {
                this.props.onClick(value);
            }
        }
    }

    $showSubMenu() {
        if (this.state.menu && this._id) {
            AddMenuAction.execute({menu: this.state.menu});
        }
    }

    _onMouseEnter() {
        if (Browser.isMobile()) {
            return;
        }

        this.$showSubMenu();
    }

    _onMouseLeave(event) {
        if (Browser.isMobile()) {
            return;
        }
        
        let target = event.relatedTarget;

        //Note IE10, does not support dataset properties, and must use getAttribute.

        let menu = this.props.menu;

        // if (!target.getAttribute('data-menu-id')) {
        //  DeleteMenuAction.execute(menu.getID());
        //  return;
        // }

        let parentMenuID = menu.props.parentMenuID;

        let acceptableIDs = [menu.props.id];
        if (parentMenuID) {
            acceptableIDs.push(parentMenuID);
        }

        if (target && target.getAttribute && target.getAttribute('data-menu-id')) {
            acceptableIDs.push(target.getAttribute('data-menu-id'));
        }

        let activeMenus = MenuStore.getInstance().getMenus();
        for (let i = 0; i < activeMenus.length; i++) {
            let activeMenu = activeMenus[i];
            if (acceptableIDs.indexOf(activeMenu.props.id) === -1) {
                DeleteMenuAction.execute({key: activeMenu.props.id});
            }
        }
    }

    isSubMenuItem(target) {
        if (!target.dataset) {
            return false;
        }

        let parentMenuID = target.getAttribute('parent-menu-id');
        let menuID = this.props.menuID;

        return parentMenuID === menuID;
    }

    isNodeChildOf(child, parent) {
        if (!child.parentNode) {
            return false;
        }

        while (child && child.parentNode !== parent) {
            child = child.parentNode;
        }

        return !!child; //If child is set, then it found a matching parentNode
    }

    componentDidMount() {
        if (this.props.option instanceof Option) {
            let value = this.props.option.getValue();
            if (value instanceof Array && !this.state.menu) {
                if (!this._id) {
                    this._id = IDGenerator.generate() + '-sub'; 
                }
                
                this.setState({
                    menu : (
                        <Menu
                            scrollable={this.props.scrollable} 
                            key={this._id} 
                            id={this._id} 
                            className={this.props.className ? this.props.className + '-submenu' : 'submenu'}
                            relativeTo={this._node}
                            alignment={Menu.ALIGNMENT_RIGHT_TOP}
                            allowFlip
                            onClick={this._onItemClick}
                            options={value}
                            parentMenuID={this.props.menuID}
                        />
                    )
                });
            }
        }

        this._scrollIntoView(true);
    }

    componentWillUnmount() {
        if (this.state.menu && this._id && MenuStore.getInstance().containsMenu(this._id)) {
            DeleteMenuAction.execute({key: this._id});
        }
    }

    _renderContent() {
        let deletable = !!this.props.option.getOnDelete();

        let icon = null;
        if (this.state.menu) {
            icon = IconFactory.create('caret-right', {
                key: 'icon'
            });
        }

        return [
            <div key="menu-text" className="menu-text">{this.props.option.getText()}</div>,
            deletable ? this._renderDeleteIcon(this.props.option.getOnDelete()) : null,
            icon
        ];
    }

    _getClassName() {
        return '';
    }

    _scrollIntoView(skipAnimation) {
        if (this._node && this.props.highlight) {
            this._node.scrollIntoView({
                behavior: skipAnimation ? 'instant' : 'smooth',
                block: 'nearest'
            });
        }
    }

    componentDidUpdate() {
        this._scrollIntoView();
    }

    render() {
        this._scrollIntoView();

        return (
            <div 
                data-menu-id={this.props.menuID}
                data-parent-menu-id={this.props.parentMenuID}
                className={
                    ClassName.execute({
                        ...this.props.option.getUserClasses(),
                        'MenuItem': true,
                        [this._getClassName()]: true,
                        'highlight': this.props.highlight,
                        'readonly': !this.props.option.isEnabled()
                    })
                }
                onClick={this._onClick}
                ref={(c) => { this._node = c; }}
                onMouseEnter={this._onMouseEnter}
                onMouseLeave={this._onMouseLeave}
                title={this.props.option.getText()}
            >
                {this._renderContent()}
            </div>
        );
    }

    _renderDeleteIcon(onDelete) {
        return IconFactory.create(IconFactory.Icons.THIN_CANCEL, {
            onClick: (e) => {
                if (this.props.option.isEnabled()) {
                    onDelete(e);
                    ClearMenusAction.execute();
                }
            },
            className: "menu-item-delete-icon",
            key: 'menu-item-delete-icon'
        });
    }
}

MenuItem.propTypes = {
    onClick: PropTypes.func,
    option: PropTypes.oneOfType([
        PropTypes.instanceOf(Option),
        PropTypes.instanceOf(OptionGroup)
    ]),
    userClasses: PropTypes.objectOf(PropTypes.string),
    menu: PropTypes.object,
    menuID: PropTypes.string,
    scrollable: PropTypes.bool,
    className: PropTypes.string,
    parentMenuID: PropTypes.string,
    title: PropTypes.string,
    highlight: PropTypes.bool
};
