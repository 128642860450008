'use strict';

import {EventEmitter} from 'events';
import { KeyCode } from './KeyCode';

class KeyboardListenerSingleton extends EventEmitter {
    constructor() {
        super();
        this.setMaxListeners(Infinity);

        this._onKeyDown     = this._onKeyDown.bind(this);
        this._onKeyPress    = this._onKeyPress.bind(this);
        this._onKeyUp       = this._onKeyUp.bind(this);

        window.addEventListener('keydown', this._onKeyDown);
        window.addEventListener('keypress', this._onKeyPress);
        window.addEventListener('keyup', this._onKeyUp);
    }

    _createEventObject(event) {
        return {
            altKey: event.altKey,
            ctrlKey : event.ctrlKey,
            shiftKey: event.shiftKey,
            keyCode: event.keyCode,
            target: event.target,
            nativeEvent: event
        };
    }

    _onKeyDown(event) {
        this.emit(KeyboardListener.E_KEYDOWN, this._createEventObject(event));
    }

    _onKeyPress(event) {
        this.emit(KeyboardListener.E_KEYPRESSS, this._createEventObject(event));
    }

    _onKeyUp(event) {
        this.emit(KeyboardListener.E_KEYUP, this._createEventObject(event));
    }

    register(event, handler) {
        this.on(event, handler);
    }

    unregister(event, handler) {
        this.removeListener(event, handler);
    }

    isKeyCodeTextual(keyCode) {
        if (keyCode >= KeyCode.A && keyCode <= KeyCode.Z) {
            return true;
        }

        if (keyCode >= KeyCode.ZERO && keyCode <= KeyCode.NINE) {
            return true;
        }

        if (keyCode >= KeyCode.NUMPAD_ZERO && keyCode <= KeyCode.NUMPAD_ADD) {
            return true;
        }

        if (keyCode >= KeyCode.NUMPAD_MINUS && keyCode <= KeyCode.NUMPAD_DIVIDE) {
            return true;
        }

        if (keyCode >= KeyCode.SEMICOLON && keyCode <= KeyCode.BACKQUOTE) {
            return true;
        }

        if (keyCode >= KeyCode.BRACKET_OPEN && keyCode << KeyCode.QUOTE) {
            return true;
        }

        return false;
    }
}

const KeyboardListener = new KeyboardListenerSingleton();

KeyboardListener.E_KEYDOWN      = 'keydown';
KeyboardListener.E_KEYPRESSS    = 'keypress';
KeyboardListener.E_KEYUP        = 'keyup';

export { KeyboardListener };
