
/**
 * Copyright 2023-2024 Total Pave Inc.
 * All Rights Reserved.
 */

/**
 * @since 1.0.0
 */
export class ClassName {
    private constructor() {}

    public static execute(classes: Record<string, boolean>, defaults?: string[]): string {
        let parts: string[] = [];

        if (defaults) {
            parts = parts.concat(defaults);
        }

        for (let i in classes) {
            if (classes[i]) {
                parts.push(i);
            }
        }

        return parts.join(' ');
    }
}
