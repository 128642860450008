'use strict';

import {DateFactory} from './DateFactory';

class JSDateFactory extends DateFactory {
    create() {
        return new Date(...arguments);
    }
}

export { JSDateFactory };
