'use strict';

import {Action} from './Action';

class DeleteMenuActionSingleton extends Action {
    constructor() {
        super();
        this.setForceExecute(true);
    }
    
    getTag() {
        return 'delete_menu';
    }

    _shouldDispatchOnError() {
        return false;
    }

    _execute({key} = {}) {
        return Promise.resolve(key);
    }
}

const DeleteMenuAction = new DeleteMenuActionSingleton();

export { DeleteMenuAction };
