
import { Action } from '@totalpave/actions';
import { Currency } from '@totalpave/interfaces';
import { LocaleTools } from '../localization/LocaleTools';

export interface ICurrencyLocale {
    locale: string;
    currency: Currency;
}

export class LoadCurrencyLocalization extends Action<Currency, ICurrencyLocale> {

    public override getTag(): string {
        return 'load-currency-localization';
    }

    _shouldDispatchOnError() {
        return false;
    }

    /**
     * public execute
     *
     *  Dispatches:
     *  {
     *      locale      : string,
     *      currency    : Currency
     *  }
     * 
     * @param  {Currency} currency
     * @return {void}
     */
    protected override async _execute(currency: Currency): Promise<ICurrencyLocale> {
        return {
            locale: LocaleTools.adaptCurrencyCode(currency),
            currency: currency
        };
    }
}
