'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import '../style/Header.less';

/*
    A component that is fixed to the top of the screen.
 */
class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lineCount: 0
        };

        this._node = null;
        // this.$isDirty = false;
        this.$tickID = null;
        this.$responsiveRenderTick = this.$responsiveRenderTick.bind(this);
        this.$isUnmounting = false;
    }

    componentDidMount() {
        // this.$isDirty = true;
        this.$isUnmounting = false;
        this.$tickID = window.requestAnimationFrame(this.$responsiveRenderTick);
    }

    componentWillUnmount() {
        this.$isUnmounting = true;
        window.cancelAnimationFrame(this.$tickID);
    }

    $responsiveRenderTick() {
        if (this.$isUnmounting) {
            return;
        }

        let lineCount = this.getLineCount();
        
        if (lineCount > 0) {
            // this.$isDirty = false;
            this.setState({
                lineCount: lineCount
            });
        }

        this.$tickID = window.requestAnimationFrame(this.$responsiveRenderTick);
    }

    render() {
        let responsiveFontClass = '';
        let lineCount = this.state.lineCount;
        if (this.props.responsiveFont && lineCount > 1) {
            responsiveFontClass = `responsive-font-${lineCount}`;
        }

        return (
            <div className={`Header ${responsiveFontClass} ${this.props.centered ? "centered" : ""}`} ref={(n) => { this._node = n; }}>
                {/* <div
                    className="header-icons left-icons"
                    ref={(n) => { this._leftIconsNode = n; }}
                >
                    {this.props.leftIcons || []}
                </div> */}
                <div className="title-container">
                    <span className="title logical">{this.props.title}</span>
                    <span className={`title user-display${this.isOverflowing() ? ' overflowing' : ''}`}>{this.props.title}</span>
                </div>
                <div className="header-icons">
                    <div className="left-icons" ref={(n) => { this._leftIconsNode = n; }}>
                        {this.props.leftIcons || []}
                    </div>
                    <div className="right-icons" ref={(n) => { this._rightIconsNode = n; }}>
                        {this.props.rightIcons || []}
                    </div>
                </div>
                {/* <div
                    className="header-icons right-icons"
                    ref={(n) => { this._rightIconsNode = n; }}
                >
                    {this.props.rightIcons || []}
                </div> */}
            </div>
        );
    }

    getNode() {
        return this._node;
    }

    isOverflowing() {
        if (!this._node) {
            return 0;
        }

        let iconsWidth = 0;

        if (this._leftIconsNode) {
            iconsWidth = this._leftIconsNode.offsetWidth;
        }

        if (this._rightIconsNode) {
            iconsWidth = this._rightIconsNode.offsetWidth;
        }

        let span = this._node.querySelector('.title.logical');

        return (span.offsetWidth + iconsWidth) >= this._node.offsetWidth;
    }

    getLineCount() {
        if (!this._node) {
            return 0;
        }

        let span = this._node.querySelector('.title.logical');

        return Math.ceil(span.clientHeight / parseInt(window.getComputedStyle(span).lineHeight));
    }
}

Header.propTypes = {
    responsiveFont: PropTypes.bool,
    centered: PropTypes.bool,
    title: PropTypes.string.isRequired,
    leftIcons: PropTypes.node,
    rightIcons: PropTypes.node
};

export { Header };
